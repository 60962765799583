import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import FadeLeft from "../../Base/FadeLeft";
import FadeRight from "../../Base/FadeRight";
import FadeIn from "../../Base/FadeIn";
import { API_URL } from "../../Constants";

export default function Token() {
  const [info, setInfo] = useState({
    twitterFollowers: "N/A",
    marketCap: "N/A",
    uniqueHolders: "N/A",
  });

  useEffect(() => {
    async function fetchBetsInfo() {
      const url = `${API_URL}/landmark/bets/getinfo`;
      const response = await fetch(url);
      const info = await response.json();
      if (info && info.twitterFollowers) {
        // only set if info is valid
        setInfo(info);
      }
    }

    fetchBetsInfo();

    return () => {};
  }, []);

  const initialSupply = 10000000000;

  const chart =
    info && info.burnedTokens
      ? {
          series: [initialSupply - info.burnedTokens, info.burnedTokens],
          options: {
            chart: {
              width: "100%",
              type: "pie",
            },
            labels: ["Remaining Tokens", "Burned Tokens"],
            theme: {
              monochrome: {
                enabled: true,
              },
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -5,
                },
              },
            },
            dataLabels: {
              enabled: false,
              formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [name, val.toFixed(1) + "%"];
              },
            },
            legend: {
              show: false,
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return ((value / initialSupply) * 100).toFixed(2) + " %";
                },
              },
            },
            xaxis: {
              labels: {
                formatter: function (value) {
                  return ((value / initialSupply) * 100).toFixed(2) + " %";
                },
              },
            },
          },
        }
      : null;

  function addCommasToNumber(number) {
    // Ensure the input is a number
    if (isNaN(number)) {
      return number;
    }

    // Use toLocaleString to add commas
    return number.toLocaleString("en-US");
  }

  return (
    <section className="token">
      <div className="token__bg">
        {/*  {/* <img src="./images/bg.png" alt="" /> */}
        <img src="./images/bg-shape-4.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="token__inner">
          <FadeRight delay={0.2}>
            <h2 className="mob-only big text-title">TOKENOMICS</h2>
          </FadeRight>
          <div className="token__inner-row">
            <FadeIn delay={0.4} className="token__inner-content token">
              <div className="title-container no-mob">
                <div>$BETS Token</div>
              </div>
              <div className="metrics-container">
                <h2 className="big">
                  {addCommasToNumber(initialSupply - info.burnedTokens)}
                  <small>
                    {" "}
                    <br className="mob-only"></br>Current Supply
                  </small>
                </h2>
              </div>
              <div className="burned-container">
                <div className="burned-breakdown">
                  <div className="burned-counter initial">
                    {addCommasToNumber(initialSupply)}
                    <br className="mob-only"></br>
                    <span>Initial Supply</span>
                  </div>
                  <div className="burned-counter">
                    {addCommasToNumber(info.burnedTokens)}*
                    <br className="mob-only"></br>
                    <span>Tokens Burned</span>
                  </div>
                  <div className="info">
                    *refreshed in real-time with <br />
                    deflationary burn mechanics
                  </div>
                </div>
                {chart && (
                  <div className="token__inner-chart_null token-chart no-mob">
                    <div id="chart">
                      <Chart
                        options={chart.options}
                        series={chart.series}
                        type="pie"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="metrics-container">
                <h2 className="big red">
                  {addCommasToNumber(info.burnedTokens)}
                  <small>
                    {" "}
                    <br className="mob-only"></br>Burned Tokens
                  </small>
                </h2>
              </div>
              <div className="metrics-container">
                <h2 className="big">
                  {addCommasToNumber(initialSupply - info.burnedTokens)}
                  <small>
                    {" "}
                    <br className="mob-only"></br>Current Supply
                  </small>
                </h2>
              </div> */}
              {/* {chart && (
                <div className="token__inner-chart_null token-chart">
                  <div id="chart">
                    <Chart
                      options={chart.options}
                      series={chart.series}
                      type="pie"
                    />
                  </div>
                </div>
              )} */}
            </FadeIn>
            <FadeRight delay={0.2}>
              <h2 className="mob-only big text-title">METRICS</h2>
            </FadeRight>
            <FadeIn delay={0.4} className="token__inner-content rev">
              <div className="title-container no-mob">
                <div>Metrics</div>
              </div>
              <div className="metrics-container">
                <div>
                  <h2 className="big">
                    {info.twitterFollowers}
                    <small>
                      {" "}
                      <br className="mob-only"></br>Followers
                    </small>
                  </h2>
                </div>
                <div className="tokenItem__icon">
                  <img src="./images/icons/token/x.png" alt="" />
                </div>
              </div>

              <div className="metrics-container">
                <div>
                  <h2 className="big">
                    {info.marketCap}
                    <small>
                      {" "}
                      <br className="mob-only"></br>Market Cap
                    </small>
                  </h2>
                </div>
                <div className="tokenItem__icon">
                  <img src="./images/icons/token/market.png" alt="" />
                </div>
              </div>

              <div className="metrics-container">
                <div>
                  <h2 className="big">
                    {info.uniqueHolders}
                    <small>
                      {" "}
                      <br className="mob-only"></br>Unique Holders
                    </small>
                  </h2>
                </div>
                <div className="tokenItem__icon">
                  <img src="./images/icons/token/holder.png" alt="" />
                </div>
              </div>

              {/* 
              <div className="tokenItem"></div>
              <div className="tokenItem">
                <h2 className="big">
                  {info.marketCap}{" "}
                  <small>
                    {" "}
                    <br className="mob-only"></br>Market Cap
                  </small>
                </h2>
                <div className="tokenItem__icon">
                  <img src="./images/icons/token/market.png" alt="" />
                </div>
              </div>
              <div className="tokenItem">
                <h2 className="big">
                  {info.uniqueHolders}{" "}
                  <small>
                    <br className="mob-only"></br>Unique Holders
                  </small>
                </h2>
                <div className="tokenItem__icon">
                  <img src="./images/icons/token/holder.png" alt="" />
                </div>
              </div> */}
            </FadeIn>
          </div>
        </div>
      </div>
    </section>
  );
}
