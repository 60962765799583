import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import FadeRight from "../../Base/FadeRight";
import FadeIn from "../../Base/FadeIn";
import { API_URL } from "../../Constants";

export default function Charity() {
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    async function getDonations() {
      try {
        const url = `${API_URL}/landmark/bets/donations/get`;
        const response = await fetch(url);
        const donations = await response.json();
        console.log(`getDonations() - donations`, donations);
        if (donations && donations[0] && donations[0].donationId) {
          // reverse order - newest donations first
          donations.reverse();
          setDonations(donations);
        }
      } catch (error) {
        console.log(`getDonations() - Error`, error);
      }
    }
    getDonations();
    return () => {};
  }, []);

  let totalDonationsAmountUsd = 0;
  let totalDonationsAmountEth = 0;

  donations.forEach((d) => {
    totalDonationsAmountUsd += d.amountUsd;
    totalDonationsAmountEth += d.amountEth;
  });

  const addCommas = (num) => {
    return num.toLocaleString();
  };

  return (
    <section className="charity">
      <div className="charity__bg">
        <img src="./images/bg-shape-1.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="charity__inner">
          <div className="charity__inner-row">
            <FadeIn delay={0.4} className="charity__inner-image">
              <img src="./images/charity/hand.png" alt="" />
            </FadeIn>
            <FadeIn delay={0.2} className="charity__inner-content">
              <h2 className="big">CHARITY DONATIONS</h2>
              <h4 className="quote">"Breaking the Chains"</h4>
              <div className="explanation">
                Freedom means freedom to transact, freedom of expression, and
                freedom to live freely. And this is why a portion of $BETS
                transaction fees go directly to funding anti-child trafficking
                initiatives. Let's show the world the power of community and the
                power of crypto by changing it.
              </div>
              <h4>So far, we have donated:</h4>
              <h3 className="donation-amt">
                ${addCommas(totalDonationsAmountUsd)} <br></br>
                <span className="eth-amt">({totalDonationsAmountEth} ETH)</span>
              </h3>
              <h4 className="sm">Donated to:</h4>
              <div className="charity-logos-container">
                <div className="inner-container">
                  {donations
                    .filter((d) => d.imageUrl)
                    .map((d) => {
                      return (
                        <img className="img-fluid" src={d.imageUrl} alt="" />
                      );
                    })}
                </div>
              </div>
              {donations.map((d) => {
                return (
                  <p className="ex">
                    ${addCommas(d.amountUsd)} ({d.amountEth} ETH)
                    <br className="mob-only"></br>
                    <span className="no-mob donation-hyphen"> - </span>
                    <a
                      href={d.recipientUrl}
                      target="_blank"
                      className="blue-link"
                    >
                      {d.recipientName}
                    </a>
                    <br className="mob-only"></br>
                    <span className="no-mob donation-hyphen"> - </span>
                    {d.date}
                  </p>
                );
              })}
            </FadeIn>
          </div>
          {/* <Link
            to="community"
            spy={true}
            smooth={true}
            duration={500}
            className="charity__inner-arrow"
          >
            <img src="./images/icons/arrow-down-white.svg" alt="" />
          </Link> */}
        </div>
      </div>
    </section>
  );
}
