import React, { useState } from "react";
import FadeUp from "../../Base/FadeUp";
import FadeIn from "../../Base/FadeIn";

export default function Subscribe() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setMessage(""); // clear success msg

    if (validateEmail(newEmail)) {
      setError("");
    } else {
      setError("Please enter a valid email address");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!error && email) {
      try {
        const response = await fetch(
          "https://landmarkbots.com:7770/api/landmark/bets/addEmail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );
        const result = await response.json();

        if (result.success) {
          setMessage(result.success);
        } else if (result.error) {
          setMessage(result.error);
        } else {
          setMessage("An error occurred");
        }
      } catch (error) {
        setMessage("An error occurred: " + error.message);
      }
    } else {
      alert("Please enter a valid email address");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const errorMsgClass = error ? "show" : "";
  const errorMsgText = error ? error : "-";

  return (
    <div className="subscribe">
      <div className="subscribe-inner">
        {/* <FadeUp delay={0.2} className="book__inner-title">
            <h2>THE BOOK OF BETS</h2>
          </FadeUp> */}
        <FadeIn delay={0.4} className="">
          <h3>Be the first to know</h3>
          <div className="sub-input-container">
            <div className="input">
              <input
                value={email}
                type="email"
                placeholder="Your Email"
                onChange={handleChange}
                required
              ></input>
            </div>
            <div className="button secondary" onClick={handleSubmit}>
              {" "}
              Subscribe{" "}
            </div>
          </div>
          <p className={`error-msg ${errorMsgClass}`}>{errorMsgText}</p>
          {message && <p className="success-msg">{message}</p>}
        </FadeIn>
      </div>
    </div>
  );
}
