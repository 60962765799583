import React from "react";
import FadeUp from "../../Base/FadeUp";
import FadeIn from "../../Base/FadeIn";

export default function Community() {
  return (
    <section className="community">
      <div className="community__bg">
        {/*  {/* <img src="./images/bg.png" alt="" /> */}
      </div>
      <div className="auto__container">
        <div className="community__inner">
          <FadeUp delay={0.2} className="community__inner-title">
            <h2 className="big">COMMUNITY</h2>
            <h4>
              <b> All Street Bets ($BETS) </b>
              is a culture coin on
              <a href="https://www.base.org/" target="_blank">
                <b> Base </b>
              </a>
            </h4>
          </FadeUp>
          <div className="community__inner-row">
            <FadeIn delay={0.3} className="communityItem">
              <div className="communityItem__image">
                <div className="communityItem__image-inner">
                  <img src="./images/community/1.png" alt="" />
                </div>
              </div>
              <div className="communityItem__content">
                <h6>
                  We believe in <b>freedom</b>
                </h6>
                <p>
                  All Street Bets represents freedom. Freedom to transact.
                  Freedom of expression. Freedom to be free. And we are building
                  the worlds largest culturecoin community around these ideas
                  that affect us all.
                </p>
                {/* <a href="#" className="button secondary sm">
                  {" "}
                  LEARN MORE{" "}
                </a> */}
              </div>
            </FadeIn>
            <FadeIn delay={0.4} className="communityItem">
              <div className="communityItem__image">
                <div className="communityItem__image-inner">
                  <img src="./images/community/2.png" alt="" />
                </div>
              </div>
              <div className="communityItem__content">
                <h6>
                  We believe in <b>community</b>
                </h6>
                <p>
                  Our community is open to everyone who believes the way forward
                  is to embrace a decentralized future. We also intend to break
                  a few things, and build a few others.
                </p>
                {/* <a href="#" className="button secondary sm">
                  {" "}
                  LEARN MORE{" "}
                </a> */}
              </div>
            </FadeIn>
            <FadeIn delay={0.3} className="communityItem">
              <div className="communityItem__image">
                <div className="communityItem__image-inner">
                  <img src="./images/community/3.png" alt="" />
                </div>
              </div>
              <div className="communityItem__content">
                <h6>
                  We believe in <b>charity</b>
                </h6>
                <p>
                  AllStreetBets is also represented as a fundraising group with
                  a special interest in supporting nonprofit organizations and
                  their efforts in combating child trafficking around the world.
                  And we donate in areas that will make a difference.
                </p>
                {/* <a href="#" className="button secondary sm">
                  {" "}
                  LEARN MORE{" "}
                </a> */}
              </div>
            </FadeIn>
          </div>
        </div>
      </div>
    </section>
  );
}
