import React from "react";
import FadeUp from "../../Base/FadeUp";
import FadeIn from "../../Base/FadeIn";
import Subscribe from "./Subscribe";

export default function Book() {
  return (
    <section className="book">
      <div className="book__bg">
        {/*  {/* <img src="./images/bg.png" alt="" /> */}
        <img src="./images/bg-shape-2.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="book__inner">
          <FadeUp delay={0.2} className="book__inner-title">
            <h2>THE BOOK OF BETS</h2>
          </FadeUp>
          <div className="book__inner-row">
            <FadeIn delay={0.4} className="book__inner-content">
              <p className="lg">
                An unforgettable story is being written about Max Bets, our
                community’s champion and mascot, and will be released in time.
              </p>
              <br />
              <h2>SO, WHAT ARE YOU WAITING FOR?</h2>
              <a
                href="https://t.me/ASBportal"
                target="_blank"
                className="button secondary"
              >
                {" "}
                JOIN US{" "}
              </a>
            </FadeIn>
            <FadeIn delay={0.6} className="book__inner-image">
              <img src="./images/book.png" alt="" />
            </FadeIn>
          </div>
          <Subscribe />
        </div>
      </div>
    </section>
  );
}
