import React from "react";
import FadeLeft from "../../Base/FadeLeft";
import FadeIn from "../../Base/FadeIn";
import { Link } from "react-scroll";

export default function Info() {
  return (
    <section className="info">
      <div className="info__bg">
        {/*  {/* <img src="./images/bg.png" alt="" /> */}
        <img src="./images/bg-shape-2.png" alt="" />

        <div className="info__bg-image">
          <img src="./images/info.png" alt="" />
        </div>
      </div>
      <div className="auto__container">
        <div className="info__inner">
          <FadeLeft delay={0.2} className="info__inner-title">
            <h2>How to buy on Uniswap:</h2>
          </FadeLeft>
          <div className="info__inner-row">
            <FadeIn delay={0.2} className="infoItem">
              <div className="infoItem__icon big">
                <img src="./images/icons/info/wallet.png" alt="" />
              </div>
              <h6 className="sm">CREATE A WALLET:</h6>
              <p>
                Download Metamask, Coinbase Wallet, or your wallet of choice
                from the App Store or Google Play store for free. Desktop users,
                download the Google Chrome extension by going to{" "}
                <a
                  className="blue-link"
                  href="https://metamask.io/"
                  target="_blank"
                >
                  metamask.io
                </a>
                .
              </p>
            </FadeIn>
            <FadeIn delay={0.3} className="infoItem">
              <div className="infoItem__icon ex">
                <img src="./images/icons/info/eth.svg" alt="" />
              </div>
              <h6 className="sm">GET SOME BASE ETH:</h6>
              <p>
                Have Base ETH in your wallet to swap to $BETS. If you don’t have
                any Base ETH, you can buy directly on Metamask, transfer from
                another wallet, or buy on another exchange and send it to your
                wallet.
              </p>
            </FadeIn>
            <FadeIn delay={0.4} className="infoItem">
              <div className="infoItem__icon">
                <img src="./images/icons/info/uniswap.png" alt="" />
              </div>
              <h6 className="sm">GO TO UNISWAP:</h6>
              <p>
                Go to{" "}
                <a
                  className="blue-link"
                  href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputcurrency=0x42069dE48741Db40aeF864F8764432bBccbD0B69&chain=base"
                  target="_blank"
                >
                  app.uniswap.org
                </a>{" "}
                in Google Chrome or on the browser inside your Metamask app.
                Connect your wallet. Paste the{" "}
                <Link
                  to="socials"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="blue-link"
                >
                  $BETS token address
                </Link>{" "}
                into Uniswap, select BETS, and confirm. When Metamask prompts
                you for a wallet signature, sign.
              </p>
            </FadeIn>
            <FadeIn delay={0.5} className="infoItem">
              <div className="infoItem__row">
                <div className="infoItem__eth">
                  <img src="./images/icons/info/eth.png" alt="" />
                </div>
                <span>
                  <img src="./images/icons/info/switch.png" alt="" />
                </span>
                <div className="infoItem__icon">
                  <img src="./images/icons/info/bet.png" alt="" />
                </div>
              </div>
              <h6 className="sm">Swap ETH for $BETS</h6>
              <p>
                We have a 3% buy / sell fee so we recommend you use between 3%
                to 5% slippage, although you may need to use more slippage
                during times of market volatility.
              </p>
            </FadeIn>
          </div>
        </div>
      </div>
    </section>
  );
}
