import React from "react";
import FadeIn from "../../Base/FadeIn";

export default function Project() {
  return (
    <section className="project">
      <div className="project__bg">
        {/* <img src={process.env.PUBLIC_URL + "/images/bg.png"} alt="token" /> */}
        <img src="./images/bg-shape-3.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="project__inner">
          <div className="project__inner-row">
            <FadeIn delay={0.2} className="project__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo-big.svg"}
                alt="logo"
              />
              <img
                className="mob"
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </FadeIn>
            <FadeIn delay={0.4} className="project__inner-content">
              <h3 className="big">
                We are a <b>community</b> <br />
                with a <span>culturecoin</span>
              </h3>
              <p className="lg">
                $BETS was built for longevity with a strategically designed 3%
                fee structure to fund charity & development efforts and outlast
                meme coin cycles. The fee structure is broken down in our
                whitepaper.
              </p>
              <a
                href="https://medium.com/@allstreetb/all-street-bets-shifting-the-financial-power-of-cultural-narratives-for-change-f8dffcc7ffb2"
                target="_blank"
                className="button secondary whitepaper-btn"
              >
                {" "}
                Whitepaper{" "}
              </a>
            </FadeIn>
          </div>
        </div>
      </div>
    </section>
  );
}
