import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Home from "./Home/Home";
import Mint from "./Mint/Mint";
import toast, { Toaster } from "react-hot-toast";
export default function App() {
  return (
    <>
      <div>
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "#0065FA",
                color: "#ffffff",
              },
              iconTheme: {
                primary: "#28A8E9",
                secondary: "#ffffff",
              },
            },
            error: {
              style: {
                background: "red",
              },
            },
          }}
        />
      </div>
      <Header />
      <main className="main">
        <Routes>
          <Route path="/mint" element={<Mint />} />
          <Route path="" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}
