import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

export default function Footer() {
  const [sticky, setSticky] = useState(true);
  const [menu, setMenu] = useState(false);
  var body = document.body;

  // function scrollFunc() {
  //   if (window.scrollY >= 60) {
  //     setSticky(true);
  //   } else setSticky(false);
  // }
  // window.onscroll = function () {
  //   scrollFunc();
  // };
  return (
    <footer className="footer">
      <div className="auto__container">
        <div className="footer-container">
          <div className="left">
            <div className="socials-container">
              <a target="_blank" href="https://twitter.com/AllStreetBetsV1">
                <img src="/images/icons/community/x.png" />
              </a>
              <a target="_blank" href="https://t.me/ASBportal">
                <img src="/images/icons/community/telegram.png" />
              </a>
              <a target="_blank" href="https://discord.gg/jzWuef4qe8">
                <img src="/images/icons/community/discord.png" />
              </a>
            </div>
          </div>
          <div className="center">
            <div class="logo">
              <img src="/images/logo.svg" alt="logo" />
            </div>
          </div>
          <div className="right">
            <a href="mailto:dev@allstreetbets.com">
              <div className="contact">
                dev@allstreetbets.com<span className="hidden-text">.</span>
              </div>
            </a>
            <div className="copyright">
              © 2024 Allstreet Bets. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
