import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom for internal navigation

export default function Header() {
  const [sticky, setSticky] = useState(true);
  const [menu, setMenu] = useState(false);

  return (
    <header className={"header " + (sticky ? "sticky" : "")}>
      <div className="auto__container">
        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" />
          </Link>
          <nav className={"nav " + (menu ? "active" : "")}>
            <ul className="nav__inner">
              <li>
                <a href="/" onClick={() => setMenu(!menu)} className="links">
                  HOME
                </a>
              </li>
              <li>
                <a
                  href="/#about"
                  onClick={() => setMenu(!menu)}
                  className="links"
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  href="/#info"
                  onClick={() => setMenu(!menu)}
                  className="links"
                >
                  HOW TO BUY
                </a>
              </li>
              <li>
                <a
                  href="/#token"
                  onClick={() => setMenu(!menu)}
                  className="links"
                >
                  TOKENOMICS
                </a>
              </li>
              <li>
                <a
                  href="/#community"
                  onClick={() => setMenu(!menu)}
                  className="links"
                >
                  COMMUNITY
                </a>
              </li>
              <li>
                <a
                  href="/#book"
                  onClick={() => setMenu(!menu)}
                  className="links"
                >
                  THE BOOK OF BETS
                </a>
              </li>
            </ul>
          </nav>
          <div
            className={"burger " + (menu ? "active" : "")}
            onClick={() => setMenu(!menu)}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
}
