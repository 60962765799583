import React from "react";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import FadeLeft from "../../Base/FadeLeft";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay"; // Ensure you import autoplay styles if it changes any visual aspect

const worldList = [
  {
    id: "1",
    name: "BERLIN",
    image: "/images/world/1.png",
  },
  {
    id: "2",
    name: "PARIS",
    image: "/images/world/2.png",
  },
  {
    id: "3",
    name: "PARIS",
    image: "/images/world/3.png",
  },
  {
    id: "6",
    name: "JAKARTA",
    image: "/images/world/6.png",
  },
  {
    id: "4",
    name: "PARIS",
    image: "/images/world/4.png",
  },
  {
    id: "5",
    name: "PARIS",
    image: "/images/world/5.png",
  },
];
export default function World() {
  const settings = {
    infinite: false,
    slidesToShow: 3.4,
    slidesToScroll: 1,
    arrows: true,
    speed: 600,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="world">
      <div className="world__bg">
        {/*  {/* <img src="./images/bg.png" alt="" /> */}
        <img src="./images/bg-shape-5.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="world__inner">
          <FadeLeft delay={0.2} className="world__inner-title">
            <h2 className="big">$BETS AROUND THE WORLD</h2>
          </FadeLeft>
          <Swiper
            modules={[Autoplay]} // Add the Autoplay module here
            className="world__inner-slider swiper-continuous"
            spaceBetween={50}
            slidesPerView={4}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            loop={true} // Enable looping
            autoplay={{
              delay: 0, // No delay for a continuous effect
              pauseOnMouseEnter: false,
              disableOnInteraction: true,
              reverseDirection: false,
            }}
            freeMode={true}
            freeModeMomentum={false}
            speed={5000}
            breakpoints={{
              1300: {
                slidesPerView: 4,
              },
              890: {
                slidesPerView: 3,
              },
              // when window width is >= 768px
              650: {
                slidesPerView: 2,
              },
              // when window width is >= 1024px
              0: {
                slidesPerView: 1,
              },
            }}
          >
            {worldList.map((item, index) => {
              return (
                <SwiperSlide>
                  <WorldItem {...item} key={index} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* <Slider className="world__inner-slider" {...settings}>
            {worldList.map((item, index) => {
              return <WorldItem {...item} key={index} />;
            })}
          </Slider> */}
        </div>
      </div>
    </section>
  );
}
const WorldItem = (props) => {
  return (
    <div className="worldItem">
      <h6 className="sm">{props.name}</h6>
      <div className="worldItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="image" />
      </div>
    </div>
  );
};
