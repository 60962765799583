import React from "react";
import { Link } from "react-scroll";
import FadeRight from "../../Base/FadeRight";
import FadeIn from "../../Base/FadeIn";

export default function Airdrop() {
  return (
    <section className="charity airdrop">
      <div className="charity__bg">
        <img src="./images/bg-shape-1.png" alt="" />
      </div>
      <div className="auto__container">
        <div className="charity__inner">
          <div className="charity__inner-row">
            <FadeIn delay={0.4} className="charity__inner-image">
              <img src="./images/airdrop/airdrop.png" alt="" />
            </FadeIn>
            <FadeIn delay={0.2} className="charity__inner-content">
              <h2 className="big">AIRDROP IS OUT</h2>
              <h4 className="quote">
                A small gift from $BETS to other communities
              </h4>
              <div className="explanation">
                We have recently sent out an airdrop of 4.20 $BETS to over 700k
                wallets of ETH and Base communities. This is not a simple
                airdrop but it will allow the recipients to mint a free NFT from
                the famous artist Arsonic and be part of our Discord where daily
                Alpha is shared and community vibes together.
              </div>
              <a href="/mint" className="button secondary whitepaper-btn">
                {" "}
                Check the mint page{" "}
              </a>
            </FadeIn>
          </div>
          {/* <Link
            to="community"
            spy={true}
            smooth={true}
            duration={500}
            className="charity__inner-arrow"
          >
            <img src="./images/icons/arrow-down-white.svg" alt="" />
          </Link> */}
        </div>
      </div>
    </section>
  );
}
