import React, { useEffect, useState } from "react";
import FadeUp from "../../Base/FadeUp";
import FadeIn from "../../Base/FadeIn";
import { API_URL } from "../../Constants";
const meetList = [
  {
    id: "1",
    image: "/images/meet/1.png",
    name: "Jaime Rogozinski",
    pos: "CO-FOUNDER & SERIAL DISRUPTOR",
    desc: `After birthing the earth shaking community movement known as <a class="blue-link" href="https://x.com/WallStreetBets" target="_blank">WallStreetBets</a>, Jaime returns for round two of entrepreneurial disruption in untamable decentralized form.`,
    twitter: "https://x.com/0xRogozinski",
  },
  {
    id: "2",
    image: "/images/meet/2.png",
    name: "samdude.eth",
    pos: "CO-FOUNDER & WEB3 TACTICIAN",
    desc: "With a background in senior operations and business development, and a well forged set of web3 industry skills and strategic abilities, Sam relentlessly maps and executes vision. Just a dude.",
    twitter: "https://x.com/samdudeV1",
  },
  // {
  //   id: "3",
  //   image: "/images/meet/3.png",
  //   name: "Max Bets",
  //   pos: "HEAD OF MARKETING",
  // },
  // {
  //   id: "4",
  //   image: "/images/meet/4.png",
  //   name: "Zeus",
  //   pos: "HEAD OF THE MODS",
  // },
];
export default function Meet() {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    async function getTeamMembers() {
      try {
        const url = `${API_URL}/landmark/bets/team/get`;
        const response = await fetch(url);
        const teamMembers = await response.json();
        console.log(`getTeamMembers() - teamMembers`, teamMembers);
        if (teamMembers && teamMembers[0] && teamMembers[0].teamMemberId) {
          setTeamMembers(teamMembers);
        }
      } catch (error) {
        console.log(`getTeamMembers() - Error`, error);
      }
    }
    getTeamMembers();
    return () => {};
  }, []);

  return (
    <section className="meet">
      <div className="meet__bg">
        <img src="./images/bg-shape-7.png" alt="Background" />
      </div>
      <div className="auto__container">
        <div className="meet__inner">
          <FadeUp delay={0.2} className="meet__inner-title">
            <h2 className="big">Meet the Founders</h2>
          </FadeUp>
          <FadeIn delay={0.4} className="meet__inner-row">
            {teamMembers.map((member, index) => {
              return <TeamMember {...member} key={index} />;
            })}
          </FadeIn>
        </div>
      </div>
    </section>
  );
}

// [URL=https://x.com/WallStreetBets]WallStreetBets[/URL] --> <a class="blue-link" href="https://x.com/WallStreetBets" target="_blank">WallStreetBets</a>
const convertUrlsToHtmlString = (str) => {
  const regex = /\[URL=(https?:\/\/[^\]]+)\](.+?)\[\/URL\]/g;
  return str.replace(regex, (match, url, text) => {
    return `<a class="blue-link" href="${url}" target="_blank">${text}</a>`;
  });
};

const TeamMember = (props) => {
  return (
    <div className="meetItem">
      <div className="meetItem__image">
        <a href={props.socialUrl} target="_blank" rel="noopener noreferrer">
          <div className="meetItem__image-inner">
            <img src={props.imageUrl} alt={props.name} />
          </div>
        </a>
      </div>
      <div className="meetItem__content">
        <a
          className="blue-link"
          href={props.socialUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h6 className="sm">{props.name}</h6>
        </a>
        <p className="big">{props.jobTitle.toUpperCase()}</p>
        {props.description && (
          <p
            dangerouslySetInnerHTML={{
              __html: convertUrlsToHtmlString(props.description),
            }}
          ></p>
        )}
      </div>
    </div>
  );
};
