import React, { useState, useEffect } from "react";
// import Hero from "./components/Hero";
import FadeUp from "../Base/FadeUp";
import FadeIn from "../Base/FadeIn";
import FadeLeft from "../Base/FadeLeft";
import FadeRight from "../Base/FadeRight";
import { Element, animateScroll as scroll } from "react-scroll";
import { useAccount } from "wagmi";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import { API_URL } from "../Constants";
import {
  BREAK_THE_CHAINS_ABI,
  BREAK_THE_CHAINS_ADDRESS,
} from "../Constants/contracts";

const useScreenWidth = () => {
  const [mobScreen, setMobScreen] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setMobScreen(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return mobScreen;
};

const ethPrice = 0.0; // todo: retrieve from contract
const usdEth = 3518;

const maxAllowancePerWallet = 1;
const minQty = 1;

export default function Mint() {
  const [qty, setQty] = useState(1);
  const [mintActive, setMintActive] = useState(true); // temp true
  const { address, isConnecting, isDisconnected, isConnected } =
    useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [userCanMint, setUserCanMint] = useState(false);
  const [mintingSignature, setMintingSignature] = useState(null);
  const [qtyMinted, setQtyMinted] = useState(0);
  const [waitingForTx, setWaitingForTx] = useState(false);
  const [breakTheChainsSettings, setBreakTheChainsSettings] = useState({
    allowMinting: false,
  });

  const mobScreen = useScreenWidth();

  // remaining allowance left to mint
  const maxQty = Math.max(maxAllowancePerWallet - qtyMinted, 1); // never goes below 1 (even if user cant has no allowance)
  const tokenId = 0; // update manually for now

  // const result = useReadContract({
  //   abi: BREAK_THE_CHAINS_ABI,
  //   address: BREAK_THE_CHAINS_ADDRESS,
  //   functionName: "quantityMinted",
  //   args: [address, 0],
  // });
  // console.log(`result`, result.data);

  useEffect(() => {
    async function getBreakTheChainsSettings() {
      try {
        const url = `${API_URL}/landmark/bets/breakthechains/getsettings`;
        const response = await fetch(url);
        const settings = await response.json();
        console.log(`getBreakTheChainsSettings() - settings`, settings);
        if (settings && settings.lastChangedTime) {
          // only set if info is valid
          setBreakTheChainsSettings(settings);
        }
      } catch (error) {
        console.log(`getBreakTheChainsSettings() - Error`, error);
      }
    }
    getBreakTheChainsSettings();
    return () => {};
  }, []);

  async function getQtyMinted() {
    try {
      if (!address) {
        return;
      }
      if (!isConnected) throw Error("User disconnected");

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      // The Contract object
      const breakTheChainContract = new Contract(
        BREAK_THE_CHAINS_ADDRESS,
        BREAK_THE_CHAINS_ABI,
        signer
      );
      const balance = await breakTheChainContract.quantityMinted(
        address,
        tokenId
      );
      const balanceFormatted = formatUnits(balance, 0);
      setQtyMinted(balanceFormatted);
      console.log(`qtyMinted`, balanceFormatted);
    } catch (error) {
      console.log(`getQtyMinted() - Error`, error);
    }
  }

  useEffect(() => {
    function clearMintingInfoState() {
      setUserCanMint(null);
      setMintingSignature(null);
      setQtyMinted(0);
    }

    async function fetchMintInfo() {
      const url = `${API_URL}/landmark/bets/getmintinfo/${address}`;
      const response = await fetch(url);
      const info = await response.json();
      console.log(`fetchMintInfo() - info`, info);
      if (info && info.address) {
        // only set if info is valid
        setUserCanMint(info.userCanMint);
        setMintingSignature(info.mintingSignature);
      } else {
        clearMintingInfoState();
      }
    }

    if (address) {
      fetchMintInfo();
      getQtyMinted();
    } else {
      clearMintingInfoState();
    }

    return () => {};
  }, [address]);

  const incrementQty = () => {
    setQty((prevQty) => Math.min(prevQty + 1, maxQty));
  };

  const decrementQty = () => {
    setQty((prevQty) => Math.max(prevQty - 1, minQty));
  };

  /* minting active:
  not connected: 
  connect wallet to mint
  connected:
  userCanMint:
  if already minted - "Thanks for minting"
  else - "Mint" btn
  else: Minting is currently only open to airdrop recipients
  */

  let mintBtnEnabled = false;
  let mintBtnText = ``;
  let hideMintAndShowThankYou = false;
  let hideMintAndShowNotAvailable = false;

  //Connect wallet to mint, Coming Soon, Mint
  // minting not open yet
  if (!mintActive) {
    mintBtnText = "Coming Soon";
  }
  // minting open
  else {
    // wallet not connected
    if (!isConnected) {
      mintBtnText = "Connect wallet to mint";
    }
    // wallet connected
    else {
      // airdrop recipient
      if (userCanMint) {
        // user has minted (and no allowance left)
        if (qtyMinted && qtyMinted >= maxAllowancePerWallet) {
          hideMintAndShowThankYou = true;
        }
        // user hasn't minted (or still has allowance to mint more)
        else {
          mintBtnEnabled = true;
          mintBtnText = "MINT NFT";
        }
      }
      // not airdrop recipient
      else {
        hideMintAndShowNotAvailable = true;
      }
    }
  }
  const mintBtnClass = mintBtnEnabled ? `` : `disabled`;

  const mintBtnClicked = async () => {
    if (!mintBtnEnabled) {
      return;
    }
    await mintNft();
  };

  const mintNft = async () => {
    if (!isConnected) throw Error("User disconnected");

    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      // The Contract object
      const breakTheChainContract = new Contract(
        BREAK_THE_CHAINS_ADDRESS,
        BREAK_THE_CHAINS_ABI,
        signer
      );

      const tx = await breakTheChainContract.mint(qty, mintingSignature, "0x", {
        value: ethPrice, // make sure price is in wei
      });
      setWaitingForTx(true);
      await tx.wait();
      // success
      setWaitingForTx(false);
      getQtyMinted(); // update qtyMinted so UI shows thank you msg
    } catch (error) {
      console.error("Minting failed:", error);
    }
  };

  return (
    <div>
      {/* <Element className="anchor" name="home"></Element>
      <Hero /> */}
      <section className="mint">
        <div className="mint__bg">
          <img src="./images/bg-shape-2.png" alt="" />
        </div>
        <div className="auto__container">
          <div className="mint__inner">
            <FadeUp delay={0.2} className="mint__inner-title">
              {/* <div onClick={getBalance}>GET BALANCE</div> */}
              <h2>
                {/* MINT <span className="thicc">$BETS NFT</span> SPECIAL EDITION */}
                SPECIAL EDITION 4.20 NFT MINT
              </h2>
            </FadeUp>
            <FadeRight delay={0.5}>
              <div className="mint-section-outer">
                <div className="nft-art">
                  <img
                    className="img-fluid"
                    src="./images/mint/nft-artwork.jpg"
                    alt=""
                  />
                </div>
                <div className="nft-controls">
                  {/* <div className="progress-container">
                    <div className="inner"></div>
                  </div> */}
                  <div className="web3-connect-container">
                    <w3m-button />
                  </div>
                  <div className="mint-controls">
                    <div className="stage">Open Edition</div>
                    <div className="cost-eth">
                      {qty > 1 ? (
                        <span className="cost-multiplier">
                          {qty} x {ethPrice}
                        </span>
                      ) : (
                        <>{ethPrice} ETH</>
                      )}{" "}
                      {qty > 1 ? (
                        <>
                          <span className="cost-multiplier">
                            {mobScreen ? (
                              <>
                                <div style={{ height: "0px" }}></div>
                                <br />
                              </>
                            ) : (
                              <>={` `}</>
                            )}
                          </span>
                          {(qty * ethPrice).toFixed(3)} ETH{" "}
                        </>
                      ) : (
                        <></>
                      )}
                      <span className="cost-usd">
                        (~${(usdEth * (ethPrice * qty)).toFixed(2)})
                      </span>
                    </div>
                    {/* <div className="cost-usd">-$44.04</div> */}
                    {/* minting not open to anyone */}
                    {!breakTheChainsSettings.allowMinting ? (
                      <FadeIn>
                        <div className="mint-replacement-container">
                          <div className="msg">Minting has not started yet</div>
                        </div>
                      </FadeIn>
                    ) : (
                      <>
                        {/* thanks for minting message */}
                        {hideMintAndShowThankYou ? (
                          <FadeIn>
                            <div className="mint-replacement-container">
                              <div className="msg">Thank you for minting!</div>
                              <a
                                className="link"
                                href={`https://opensea.io/assets/base/${BREAK_THE_CHAINS_ADDRESS}/${tokenId}`}
                              >
                                View NFT on Opensea
                              </a>
                            </div>
                          </FadeIn>
                        ) : null}
                        {/* minting not open to you */}
                        {hideMintAndShowNotAvailable ? (
                          <FadeIn>
                            <div className="mint-replacement-container">
                              <div className="msg">
                                You must own at least 4.20{" "}
                                <a
                                  href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputcurrency=0x42069dE48741Db40aeF864F8764432bBccbD0B69&chain=base"
                                  target="_blank"
                                >
                                  BETS
                                </a>{" "}
                                to mint
                              </div>
                            </div>
                          </FadeIn>
                        ) : null}
                        {/* waiting for tx to confirm */}
                        {waitingForTx ? (
                          <FadeIn>
                            <div className="mint-replacement-container">
                              <img
                                className="loading-icon"
                                src="./images/misc/loading-blue.svg"
                              />
                            </div>
                          </FadeIn>
                        ) : null}
                        {/* minting */}
                        {breakTheChainsSettings.allowMinting &&
                        !hideMintAndShowThankYou &&
                        !hideMintAndShowNotAvailable &&
                        !waitingForTx ? (
                          <FadeIn>
                            <div className="mint-container">
                              <div className="qty-container">
                                <div
                                  className={`qty-btn ${
                                    qty === minQty ? "disabled" : ""
                                  }`}
                                  onClick={decrementQty}
                                >
                                  <div className="operator">-</div>
                                </div>
                                <div className="qty">{qty}</div>
                                <div
                                  className={`qty-btn ${
                                    qty === maxQty ? "disabled" : ""
                                  }`}
                                  onClick={incrementQty}
                                >
                                  <div className="operator">+</div>
                                </div>
                              </div>
                              <div
                                onClick={mintBtnClicked}
                                className={`mint-btn ${mintBtnClass}`}
                              >
                                {mintBtnText}
                              </div>
                            </div>
                          </FadeIn>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className="mint-schedule">
                    <div className="left">
                      <div className="schedule-title">Mint Schedule</div>
                      <div className="schedules">
                        <div className="schedule-container">
                          <div className="schedule-icon"></div>
                          <div className="schedule-info">
                            <div className="stage">Free Mint</div>
                            <div className="date">Sunday 21st July 2024</div>
                            {/* <div className="date">To Be Announced</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      {/* <a target="_blank" href="https://opensea.io/"> */}
                      <div className="opensea-btn">
                        <img
                          className="img-fluid"
                          src="./images/mint/os-icon.png"
                        />
                      </div>
                      {/* </a> */}
                      <a
                        target="_blank"
                        href="https://opensea.io/collection/break-the-chains-nft"
                      >
                        <div className="collection-btn">View Collection</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </FadeRight>
            <FadeLeft delay={0.7}>
              <div className="artist-section-outer">
                <div className="info-container">
                  <div className="heading">About the art</div>
                  <div className="content">
                    Break the Chains represents a powerful cultural convergence
                    between artistic expression and decentralized freedom.
                    Falling down no more, as we stand up to fight against the
                    suppression of our freedom to transact. The first in a short
                    series of freedom focused editions representing the alliance
                    of ideals in a powerful decentralized movement.
                  </div>
                  <div className="heading">About the artist</div>
                  <div className="content">
                    Arsonic, A talented artist in the Web3 space, known most
                    famously for his Sgt. Pepe Contribution to the legendary
                    punk 6529 collection. Zeblocks partner & SgtPepeWorld
                    creator. A brilliant artist singing songs of freedom though
                    stories in cultural NFT art creation.
                  </div>
                  <div className="socials-container">
                    <a href="https://x.com/Ars0nic" target="_blank">
                      <img src="./images/icons/community/x.png" />
                    </a>
                    {/* <a href="https://instagram.com/" target="_blank">
                      <img src="./images/icons/community/instagram.png" />
                    </a>
                    <a href="https://t.me/" target="_blank">
                      <img src="./images/icons/community/telegram.png" />
                    </a> */}
                  </div>
                </div>
                <div className="artist-img">
                  <a href="https://x.com/Ars0nic" target="_blank">
                    <img src="./images/mint/artist-img.jpg" alt="" />
                  </a>
                </div>
              </div>
            </FadeLeft>
          </div>
        </div>
        <div style={{ height: "100px" }}></div>
      </section>
    </div>
  );
}
