import React from "react";
import Hero from "./components/Hero";
import About from "./components/About";
import Project from "./components/Project";
import Info from "./components/Info";
import Token from "./components/Token";
import Charity from "./components/Charity";
import Community from "./components/Community";
import Meet from "./components/Meet";
import World from "./components/World";
import Book from "./components/Book";
import Subscribe from "./components/Subscribe";
import { Element, animateScroll as scroll } from "react-scroll";
import Airdrop from "./components/Airdrop";

export default function Home() {
  return (
    <div>
      <Element className="anchor" name="home" id="home"></Element>
      <Hero />
      <Element className="anchor" name="airdrop" id="airdrop"></Element>
      <Airdrop />
      <Element className="anchor" name="about" id="about"></Element>
      <Project />
      <Element className="anchor" name="socials" id="socials"></Element>
      <About />
      <Element className="anchor" name="info" id="info"></Element>
      <Info />
      <Element className="anchor" name="token" id="token"></Element>
      <Token />
      <Element className="anchor" name="community" id="community"></Element>
      <Community />
      <Element className="anchor" name="charity" id="charity"></Element>
      <Charity />
      <Meet />
      <World />
      <Element className="anchor" name="book" id="book"></Element>
      <Book />
    </div>
  );
}
