import React from "react";
import { Link } from "react-scroll";
import FadeLeft from "../../Base/FadeLeft";

export default function Hero() {
  return (
    <section className="hero">
      <div className="hero__bg">
        <img src="./images/bg.png" alt="" />
        <div className="hero__bg-image">
          <img src="./images/hero.png" alt="" />
        </div>
      </div>
      <div className="auto__container">
        <div className="hero__inner">
          <div className="hero__inner-content">
            <FadeLeft delay={0.2}>
              <h1>
                BREAK{` `}
                THE{` `}
                CHAINS
              </h1>
            </FadeLeft>
            <FadeLeft delay={0.4}>
              {/* <Link
                to="info"
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                className="links"
              > */}
              <a
                href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputcurrency=0x42069dE48741Db40aeF864F8764432bBccbD0B69&chain=base"
                className="button primary"
              >
                {" "}
                Get $BETS on Base{" "}
              </a>
              {/* </Link> */}
            </FadeLeft>
            <FadeLeft delay={0.6}>
              <p className="big">
                All Street Bets ($BETS) represents freedom through a
                decentralized future. A culture coin brought to you by the
                founder of WallStreetBets & the web3 community.
              </p>
            </FadeLeft>

            <Link
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              className="hero__inner-arrow no-mob"
            >
              <img src="./images/icons/arrow-down.svg" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
