import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

// import { createWeb3Modal } from "@web3modal/wagmi/react";
// import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
// import { base } from "wagmi/chains";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

// 0. Setup queryClient
// const queryClient = new QueryClient();

// 1. Your WalletConnect Cloud project ID
const projectId = "b3cee9ef17907773adbfd7e3ff58dbbf";

// 2. Set chains
const baseChain = {
  chainId: 8453,
  name: "Base",
  currency: "ETH",
  explorerUrl: "https://basescan.org",
  rpcUrl: "https://base.drpc.org",
};

// 2. Create wagmiConfig
const metadata = {
  name: "BETS",
  description: "Break the chains",
  url: "https://allsteetbets.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: baseChain.rpcUrl, // used for the Coinbase SDK
  defaultChainId: 8453, // used for the Coinbase SDK
});

// const chains = [base];
// const config = defaultWagmiConfig({
//   chains,
//   projectId,
//   metadata,
// });

// 3. Create modal
createWeb3Modal({
  ethersConfig,
  chains: [baseChain],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  themeVariables: {
    "--w3m-accent": "#006DFF",
  },
});

// export function Web3ModalProvider({ children }) {
//   return (
//     <WagmiProvider config={config}>
//       <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
//     </WagmiProvider>
//   );
// }

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    {/* <Web3ModalProvider> */}
    <App />
    {/* </Web3ModalProvider> */}
  </BrowserRouter>
);
