import React from "react";
import FadeLeft from "../../Base/FadeLeft";
import FadeUp from "../../Base/FadeUp";
import toast, { Toaster } from "react-hot-toast";

export default function About() {
  const contractAddr = "0x42069dE48741Db40aeF864F8764432bBccbD0B69";

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(contractAddr)
      .then(() => {
        toast.success("Address copied to clipboard");
      })
      .catch((err) => {});
  };

  return (
    <section className="about">
      <div className="about__bg">
        <img src="./images/bg-shape-2.png" alt="" />
        <div className="about__bg-image">
          <img src={process.env.PUBLIC_URL + "/images/about.png"} alt="about" />
        </div>
      </div>
      <div className="auto__container">
        <div className="about__inner">
          <FadeLeft delay={0.2} className="about__inner-title">
            <h2>ABOUT</h2>
          </FadeLeft>
          <div className="about__inner-content">
            <FadeUp delay={0.2} className="input__outer">
              <h6>Contract</h6>
              <div className="input">
                <button type="button" onClick={handleCopyClick}>
                  <img className="copy" src="./images/icons/copy.svg" alt="" />
                </button>
                <input
                  type="text"
                  defaultValue={contractAddr}
                  readOnly={true}
                />
              </div>
            </FadeUp>
            <div className="about__inner-row">
              <FadeUp delay={0.4} className="aboutItem">
                <h6>Community</h6>
                <div className="aboutItem__inner">
                  <div className="aboutItem__row">
                    <a
                      href="https://twitter.com/AllStreetBetsV1"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/x.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a href="https://t.me/ASBportal" target="_blank">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/telegram.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a href="https://discord.gg/jzWuef4qe8" target="_blank">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/discord.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://instagram.com/allstreetbetsv1/"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/instagram.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://reddit.com/r/AllStreetBetsV1/"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/bot.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://facebook.com/profile.php?id=100095213523882"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/facebook.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://warpcast.com/allstreetbets"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/w.png"
                        }
                        alt="token"
                      />
                    </a>

                    <a
                      href="https://youtube.com/@AllStreetBets"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/youtube.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a href="https://tiktok.com/@allstreetbets" target="_blank">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/community/tiktok.png"
                        }
                        alt="token"
                      />
                    </a>
                  </div>
                </div>
              </FadeUp>
              <FadeUp delay={0.5} className="aboutItem">
                <h6>Buy $BETS</h6>
                <div className="aboutItem__inner">
                  <div className="aboutItem__row">
                    <a href="https://coinbase.com/wallet" target="_blank">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/bets/1a.png?v0"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://ascendex.com/en/cashtrade-spottrading/usdt/bets"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/2.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://mexc.com/exchange/BETS_USDT"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/3.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputcurrency=0x42069dE48741Db40aeF864F8764432bBccbD0B69&chain=base"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/4.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://coinmarketcap.com/currencies/all-street-bets/"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/5.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://coingecko.com/en/coins/all-street-bets"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/8.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://dextools.io/app/en/base/pair-explorer/0x27435d0e12d6069143d101a01ec6af3fd110f9dd?t=1715958746802"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/6.png"
                        }
                        alt="token"
                      />
                    </a>
                    <a
                      href="https://dexscreener.com/base/0x27435d0e12d6069143d101a01ec6af3fd110f9dd"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/bets/7.png"
                        }
                        alt="token"
                      />
                    </a>
                  </div>
                </div>
              </FadeUp>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
